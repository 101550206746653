/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import SellingPoints from 'gatsby-core-theme/src/components/atoms/selling-points';
import OperatorCta from 'gatsby-core-theme/src/components/atoms/button/operator-cta';
import Tnc from 'gatsby-core-theme/src/components/molecules/tnc';
import Bonus from 'gatsby-core-theme/src/components/molecules/bonus-box/template-one';
import CasinoDetails from 'gatsby-matrix-theme/src/components/molecules/operator-details';
import styles from './variant-one.module.scss';

export default function Row({
  item,
  tracker = 'main',
  oneliner = 'main',
  layout = 'list',
  index,
  className,
  itemRefs,
  authorTemplateTwo = false,
  showAuthor,
  useOneStarRating,
  template,
  verifyIcon = '../../../../images/verifiedAuthor.svg',
}) {
  const tandcEnabled = item?.extra_fields?.terms_and_conditions_text_enabled === '1';
  const sellingPoints = item.selling_points;
  const { ribbons } = item;

  const addToRefs = (el, itemNum) => {
    if (el && itemRefs) {
      itemRefs.current[itemNum] = el;
    }
  };

  const firstRowItems = index === 0 || index === 1 || index === 2;

  const Content = () => (
    <>
      <span className={styles.rankNumber}>#{index + 1}</span>
      <CasinoDetails
        item={item}
        tracker={tracker}
        template={template}
        prettyLinkTracker
        useOneStarRating={useOneStarRating}
        costumeStyle={styles.operatorDetails}
        authorTemplateTwo={!!((layout === 'grid' && firstRowItems) || authorTemplateTwo)}
        ribbons={ribbons}
        showRibbon
        icon={verifyIcon}
        showRating={true}
        showAuthor={true}
      />

      <Bonus item={item} tracker={oneliner} />

      <SellingPoints sellingPoints={sellingPoints} limit={3} />

      <OperatorCta
        operator={item}
        tracker={tracker}
        gtmClass="toplist-operator-cta-gtm"
        pageTemplate={template}
      />
    </>
  );

  return (
    <li
      className={`${className || ''} ${styles?.row || ''} ${
        layout === 'grid' && firstRowItems ? styles.grid || '' : styles.list || ''
      } ${!tandcEnabled ? styles?.rowNoTandC || '' : ''} ${styles[`toplist_${index}`] || ''}`}
      ref={(el) => addToRefs(el, index)}
    >
      {tandcEnabled ? (
        <>
          <div className={styles.container}>
            <Content />
          </div>
          <div className={styles.tnc || ''}>
            <Tnc isFixed hasCollapse={false} operator={item} />
          </div>
        </>
      ) : (
        <Content />
      )}
    </li>
  );
}

Row.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  tracker: PropTypes.string,
  oneliner: PropTypes.string,
  layout: PropTypes.oneOf(['list', 'grid']),
  item: PropTypes.shape({
    path: PropTypes.string,
    authorName: PropTypes.string,
    authorPath: PropTypes.string,
    founded: PropTypes.string,
    name: PropTypes.string,
    logo_url: PropTypes.string,
    rating: PropTypes.string,
    selling_points: PropTypes.arrayOf(PropTypes.string),
    review_link: PropTypes.string,
    one_liners: PropTypes.shape({
      main: PropTypes.shape({
        one_liner: PropTypes.string,
      }),
    }),
    extra_fields: PropTypes.shape({
      terms_and_conditions_text_enabled: PropTypes.string,
      ribbon_text: PropTypes.string,
      ribbon_color: PropTypes.string,
      pro_1: PropTypes.string,
      pro_2: PropTypes.string,
      pro_3: PropTypes.string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    standardised_logo_url_object: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    logo_url_object: PropTypes.object,

    bonus: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    relation: PropTypes.shape({
      rating: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string,
      logo_url: PropTypes.string,
    }),
  }).isRequired,
  showAuthor: PropTypes.bool,
  useOneStarRating: PropTypes.bool,
  verifyIcon: PropTypes.string,
};
