/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'gatsby-core-theme/src/helpers/getters';
import { Context } from 'gatsby-core-theme/src/context/MainProvider';
import keygen from '~helpers/keygen';
import Button from '~atoms/button/button';
import Row from 'gatsby-matrix-theme/src/components/molecules/toplist/row/variant-one';
import styles from 'gatsby-matrix-theme/src/gatsby-core-theme/components/organisms/toplist/list/list.module.scss';
import styleTemplateOne from 'gatsby-matrix-theme/src/gatsby-core-theme/components/organisms/toplist/list/template-one.module.scss';
import styleTemplateTwo from 'gatsby-matrix-theme/src/gatsby-core-theme/components/organisms/toplist/list/template-two.module.scss';
import styleTemplateThree from './template-three.module.scss';

export default function List({
  pagePath,
  toplist,
  hasLoadMoreButton,
  initItemsCount,
  loadItemsCount,
  pageTemplate,
  modulestyle,
  removeInactiveItems = true,
  template,
  loadMoreIcon = null,
  verifyIcon = '../../../../images/verifiedAuthor.svg',
}) {
  const initLoadItems = Number(initItemsCount) !== 0 ? Number(initItemsCount) : 10000;
  const loadingItems = Number(loadItemsCount) !== 0 ? Number(loadItemsCount) : 10000;

  const isPPCPage = pageTemplate && pageTemplate.toLowerCase() === 'ppc';
  const { translations } = useContext(Context) || {};
  const elRefs = useRef([]);
  let { items } = toplist;

  if (removeInactiveItems) {
    items = items.filter((res) => res.status === 'active');
  }

  const loadMoreBtn = useRef(React.createRef());

  const showLoadMoreButton =
    hasLoadMoreButton === '1' && Number(initItemsCount) < toplist.items.length;

  function handleClick() {
    const displayed = elRefs.current.filter((item) => item.classList.contains(styles.show)).length;

    const nextItem = loadingItems + displayed;
    const lastItem =
      nextItem > elRefs.current.length ? elRefs.current.length + 1 : displayed + loadingItems;

    elRefs.current.slice(displayed, lastItem).forEach((item) => item.classList.toggle(styles.show));

    if (loadingItems + displayed >= elRefs.current.length) {
      loadMoreBtn.current.classList.toggle(styles.hide);
    }
  }

  const templateStyle = () => {
    switch (modulestyle) {
      case 'template_two':
        return styleTemplateTwo.templateTwo;
      case 'template_three':
        return styleTemplateThree.templateThree;
      default:
        return styleTemplateOne.templateOne;
    }
  };

  const isGrid = modulestyle === 'template_two';

  if (modulestyle === 'template_three') {
    return (
      <>
        <ul className={`${styles.list || ''}  ${templateStyle()}`} key={keygen()}>
          {items.map((item, index) => (
            <Row
              pagePath={pagePath}
              item={item}
              tracker={toplist.tracker}
              oneliner={toplist.one_liner}
              isPPC={isPPCPage}
              index={index}
              className={index + 1 <= initLoadItems ? styles.show : null}
              key={keygen()}
              itemRefs={elRefs}
              template={template}
              layout={isGrid ? 'grid' : 'list'}
              verifyIcon={verifyIcon}
              showAuthor={modulestyle !== 'template_three'}
            />
          ))}
        </ul>
        {showLoadMoreButton && (
          <div ref={loadMoreBtn} className={styles.loadMore}>
            <Button
              isInternalLink={false}
              btnText={translate(translations, 'load_more', 'Load More')}
              onClick={() => handleClick()}
              buttonType="tertiary"
              icon={loadMoreIcon}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <ul className={`${styles.list || ''} ${templateStyle()} `} key={keygen()}>
        {items.map((item, index) => (
          <Row
            pagePath={pagePath}
            item={item}
            tracker={toplist.tracker}
            oneliner={toplist.one_liner}
            isPPC={isPPCPage}
            index={index}
            className={index + 1 <= initLoadItems ? styles.show : null}
            key={keygen()}
            itemRefs={elRefs}
            template={template}
            layout={isGrid ? 'grid' : 'list'}
            verifyIcon={verifyIcon}
            showAuthor={modulestyle !== 'template_three'}
          />
        ))}
      </ul>
      {showLoadMoreButton && (
        <div ref={loadMoreBtn} className={styles.loadMore}>
          <Button
            isInternalLink={false}
            btnText={translate(translations, 'load_more', 'Load More')}
            onClick={() => handleClick()}
            buttonType="tertiary"
            icon={loadMoreIcon}
          />
        </div>
      )}
    </>
  );
}

List.propTypes = {
  pagePath: PropTypes.string,
  toplist: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    tracker: PropTypes.string,
    one_liner: PropTypes.string,
  }),
  hasLoadMoreButton: PropTypes.bool,
  initItemsCount: PropTypes.string,
  loadItemsCount: PropTypes.string,
  pageTemplate: PropTypes.string,
  removeInactiveItems: PropTypes.bool,
  template: PropTypes.string,
  verifyIcon: PropTypes.string,
};
